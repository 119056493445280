import { QueryClientProvider, QueryClient } from '@tanstack/react-query'

import { Selector } from '/features/pageOnly/Selector'

const client = new QueryClient()

export default function SelectorUniversal({ image, issue, banks, issues }) {
  return (
    <QueryClientProvider {...{ client }}>
      <Selector {...{ image, issue, banks, issues }} />
    </QueryClientProvider>
  )
}
