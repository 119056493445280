import { useSpring } from '@react-spring/web'

import { useEvent } from '/machinery/useEvent'

export function useReadMoreEnterAnimation({ enabled, timeout }) {
  const [showReadMore, setShowReadMore] = React.useState(false)

  useTimeout({
    enabled,
    timeout,
    onTimeout() { setShowReadMore(true) },
    onCancel() { setShowReadMore(false) },
  })

  const readMoreAnimation = useSpring({
    y: showReadMore ? 0 : 100,
    opacity: showReadMore ? 1 : 0,
    config: { mass: 1, tension: 280, friction: 60 }
  })

  return { showReadMore, readMoreAnimation }
}

function useTimeout({ enabled, onTimeout, onCancel, timeout }) {
  const onTimeoutEvent = useEvent(onTimeout)
  const onCancelEvent = useEvent(onCancel)

  React.useEffect(
    () => {
      if (!enabled) return

      const timeoutId = setTimeout(onTimeoutEvent, timeout)

      return () => {
        clearTimeout(timeoutId)
        onCancelEvent()
      }
    },
    [enabled, timeout, onTimeoutEvent, onCancelEvent]
  )
}
