import { FloatingOverlay } from '@floating-ui/react'
import { useSpring, animated, Globals } from '@react-spring/web'

import { useMediaQuery } from '/machinery/useCachingMediaQuery'

import { ImageCover } from '/features/buildingBlocks/Image'

import mediaStyles from '/cssGlobal/media.css'
import styles from './Splash.css'

import logo from '/images/branding/rabo-en-co-logo.svg'

export function Splash({ image, children, layoutClassName = undefined }) {
  const { skipEnterAnimation, lockScroll } = useStartingScrollPosition()

  React.useEffect(
    () => {
      Globals.assign({
        skipAnimation: skipEnterAnimation,
      })
    },
    [skipEnterAnimation]
  )

  const largeLogoStyle = useSpring({
    delay: 1000,
    from: { opacity: 1, scale: 1 },
    to: { opacity: 0, scale: 0.7 },
    config: { mass: 1, tension: 500, friction: 50 }
  })

  const style = useSpring({
    delay: 1250,
    from: { opacity: 0, y: 50 },
    to: { opacity: 1, y: 0 },
    config: { mass: 0.75, tension: 250, friction: 75 }
  })

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <div className={styles.largeLogoContainer}>
        <animated.img src={logo} sizes='200px' alt="logo" style={largeLogoStyle} />
      </div>

      <animated.img src={logo} sizes='100px' alt="logo" className={styles.logo} {...{ style }} />

      <animated.div className={styles.content} {...{ style }}>
        {children}
      </animated.div>

      <BackgroundImage layoutClassName={styles.backgroundLayout} {... { image }} />

      <FloatingOverlay style={{ pointerEvents: 'none' }} {...{ lockScroll }} />
    </div>
  )
}

function BackgroundImage({ image, layoutClassName = undefined }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  const style = useSpring({
    delay: 1100,
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { mass: 1, tension: 150, friction: 50 }
  })

  return (
    <div className={cx(styles.componentBackgroundImage, layoutClassName)}>
      <animated.div className={styles.backgroundOverlay} {...{ style }} />
      <ImageCover
        aspectRatio={isViewportMd ? 16 / 9 : 9 / 16}
        imgProps={{ loading: 'eager' }}
        layoutClassName={styles.imageLayout}
        {...{ image }}
      />
    </div>
  )
}

function useStartingScrollPosition() {
  const [skipEnterAnimation, setSkipEnterAnimation] = React.useState(false)
  const [lockScroll, setLockScroll] = React.useState(false)

  React.useEffect(
    () => {
      window.addEventListener('scroll', handleScroll)

      return () => window.removeEventListener('scroll', handleScroll)

      function handleScroll() {
        if (window.scrollY > window.innerHeight) {
          if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'auto'
          }
        } else {
          if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual'
          }
          return () => {
            if ('scrollRestoration' in window.history) {
              window.history.scrollRestoration = 'auto'
            }
          }
        }
      }
    },
    []
  )

  React.useEffect(
    () => {
      if (window.scrollY <= window.innerHeight) {
        setLockScroll(true)
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' })

        setTimeout(() => {
          setLockScroll(false)
        }, 1000)
      } else {
        setSkipEnterAnimation(true)
        window.history.scrollRestoration = 'auto'
      }
    },
    []
  )

  return { skipEnterAnimation, lockScroll }
}
